  import React from "react";
import { useTranslation } from "react-i18next";
import "../i18n";
import Header from "../components/home/Header";
import { Helmet } from "react-helmet";
import PageNotFoundImage from "../images/pagenotfound.svg";
import SEO from "../components/common/seo";

const NotFound: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO />

      <Header />
      <main className="contacts-page p-6 min-h-screen main-content">
        <section className="relative pt-2">
          <div className="mx-auto text-center items-center flex flex-col">
            <div className="p-6 md:w-4/12 flex justify-center flex-col text-center items-center">
              <div className="image">
                <PageNotFoundImage />
              </div>
              <div className="flex items-center mt-6">
                <h1 className="w-96 text-neutral-black-coral">{t("notFound.title")}</h1>
              </div>
              <p className="max-w-xl text-neutral-black-coral body-text-1 flex items-center text-center mt-4">{t("notFound.description")}</p>
              <div className="mt-10">
                <a
                  href={t("notFound.button.link")}
                  className="flex content-center flex-wrap bg-celtic-blue-primary h-12 rounded font-semibold text-sm text-background-milk px-20"
                >
                  {t("notFound.button.displayText")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default NotFound;
